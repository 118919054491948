.boxListInvoice {
  padding      : 0 20px;
  border-bottom: 1px solid #C3C4C6;
}

.boxListInvoice .textlist {
  width: 100%;
}

.boxListInvoice .textlist-num {
  color      : #3279B7;
  font-weight: 500;
  width      : 270px;
  text-align : right;
}

.boxListInvoice>.bRow {
  margin-bottom: 15px;
}

.boxTotalInvoice {
  padding: 10px 20px 0;
  border-bottom: 1px solid #C3C4C6;
}

.boxTotalInvoice>.bRow {
  margin-bottom: 10px;
}

.boxTotalInvoice .textlist {
  text-align   : right;
  width        : 100%;
  padding-right: 20px;
  color        : #333333;
  font-size    : 18px;
  font-weight  : 500;
}

.boxTotalInvoice .textlist-num {
  color      : #024D85;
  font-size  : 18px;
  font-weight: 500;
  width      : 270px;
  text-align : right;
}
.boxGrandTotalInvoice{
  padding: 10px 20px 0;
}
.boxGrandTotalInvoice .textlist {
  text-align   : right;
  width        : 100%;
  padding-right: 20px;
  color        : #333333;
  font-size    : 24px;
  font-weight  : 500;
}

.boxGrandTotalInvoice .textlist-num {
  color      : #024D85;
  font-size  : 24px;
  font-weight: 500;
  width      : 270px;
  text-align : right;
  text-decoration: underline;
}
