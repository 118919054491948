/** @format */

.bg-loading {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  opacity: 0.8;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hidden {
  display: none;
}
.w-auto {
  width: auto;
}
.pl-5px {
  padding-left: 5px;
}
.ml-10px {
  margin-left: 5px !important;
}
.d-flex-space-evenly {
  display: flex;
  justify-content: space-evenly;
}

.d-flex-ralign-jflexend {
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.color-primary {
  color: #024d85;
}

.pointer {
  cursor: pointer;
}

.d-none {
  display: none !important;
}

.MuiButton-root {
  min-width: 0px !important;
}
