.LoginPageMain {
  display: block;
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  background: url(../images/bg-login.jpg) no-repeat center center #ffffff;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.LoginPageMain:before,
.LoginPageMain:after {
  content: "";
  width: 100%;
  height: 5px;
  background-color: #024d85;
  position: absolute;
  left: 0;
}

.LoginPageMain:before {
  top: 0;
}

.LoginPageMain:after {
  bottom: 0;
}

.LoginFormMain {
  width: 90%;
  padding: 30px 25px;
  max-width: 460px;
}

.BoxLogo {
  display: flex;
  justify-content: center;
  margin-bottom: 28px;
}

.Logo {
  background: url(../images/logo-main.png) no-repeat center center;
  background-size: 100% auto;
  display: block;
  width: 235.06px;
  height: 232.51px;
}

.MuiInputBase-input {
  padding-bottom: 5px !important;
  padding-top: 5px !important;
}

.inputUser .MuiInputBase-input {
  padding-left: 45px !important;
}

.inputUser .MuiInputLabel-formControl,
.inputPass .MuiInputLabel-formControl {
  left: 45px !important;
}

.inputPass .MuiInputBase-input {
  padding-left: 45px !important;
  padding-right: 45px !important;
}

.inputUser:before,
.inputPass:before,
.inputPass:after {
  content: "";
  width: 24px;
  height: 24px;
  display: block;
  position: absolute;
  top: 20px;
}

.inputUser:before,
.inputPass:before {
  left: 10px;
}

.inputUser:before {
  background: url(../images/user.svg) no-repeat center center;
  background-size: 100% auto;
}

.inputPass:before {
  background: url(../images/pass.svg) no-repeat center center;
  background-size: 100% auto;
}

/* .inputPass:after {
  right          : 10px;
  background     : url(../images/eye.svg) no-repeat center center;
  background-size: 100% auto;
} */

.loginFooter {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 12px;
  text-align: center;
  line-height: 40px;
}
