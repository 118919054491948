html {
	background     : url(../images/bg-main.jpg) no-repeat center bottom #ffffff;
	background-size: cover;
}

body {
	font-family        : 'Kanit', sans-serif;
	font-size          : 16px;
	color              : #555555;
	/* background-color: #F6F7F9; */
	padding-top        : 69px;
}

/* --------------------------------------------------- Mobile --------------------------------------------------- */
@media only screen and (min-width: 320px) and (max-width: 767px) {
	body {
		padding-top: 61px;
	}

}