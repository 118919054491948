/**
 * /*----------------- Fix Color Style -----------------
 *
 * @format
 */

.fixColorTextThemes {
  color: #3279b7 !important;
}

/*----------------- Main Style -----------------*/
.styleBtn {
  font-family: "Kanit", sans-serif !important;
  font-size: 14px !important;
  letter-spacing: 1px !important;
  width: 100px;
  text-align: center;
  background-color: #e8e8e8 !important;
  color: #555555 !important;
}

.styleBtn.space {
  margin: 0 5px;
}
.styleBtn.w150 {
  width: 150px;
}

.styleBtn:hover {
  background-color: #d0d0d0 !important;
}

.styleBtn.blue {
  background-color: #3279b7 !important;
  color: #ffffff !important;
}

.styleBtn.blue:hover {
  background-color: #024d85 !important;
}

.styleBtn.green {
  background-color: #14a725 !important;
  color: #ffffff !important;
}

.styleBtn.green:hover {
  background-color: #138921 !important;
}

.styleBtn.red {
  background-color: #b73232 !important;
  color: #ffffff !important;
}

.styleBtn.red:hover {
  background-color: #a71b1b !important;
}

.styleBtn.pink {
  background-color: #ff6262 !important;
  color: #ffffff !important;
}

.styleBtn.pink:hover {
  background-color: #ee3939 !important;
}

.styleBtn.purple {
  background-color: #b362ff !important;
  color: #ffffff !important;
}

.styleBtn.purple:hover {
  background-color: #9639ee !important;
}

.styleBtn.yellow {
  background-color: #a8bd5b !important;
  color: #ffffff !important;
}

.styleBtn.yellow:hover {
  background-color: #b8c97e !important;
}

.styleBtn.orange {
  background-color: #dc9410 !important;
  color: #ffffff !important;
}

.styleBtn.orange:hover {
  background-color: #dba031 !important;
}

.styleBtn.lightgreen {
  background-color: #31d2cc !important;
  color: #ffffff !important;
}

.styleBtn.lightgreen:hover {
  background-color: #2ae9e2 !important;
}

.styleBtn.yellowdark {
  background-color: #6d6e02 !important;
  color: #ffffff !important;
}

.styleBtn.yellowdark:hover {
  background-color: #909203 !important;
}

.styleBtn.orangedark {
  background-color: #e4735b !important;
  color: #ffffff !important;
}

.styleBtn.orangedark:hover {
  background-color: #ec826a !important;
}

.styleBtn.darkblue {
  background-color: #222ca5 !important;
  color: #ffffff !important;
}

.styleBtn.darkblue:hover {
  background-color: #2d39c2 !important;
}

.styleBtn.lightblue {
  background-color: #7481fa !important;
  color: #ffffff !important;
}

.styleBtn.lightblue:hover {
  background-color: #5162fc !important;
}

.styleBtn.lightpurple {
  background-color: #8d2960 !important;
  color: #ffffff !important;
}

.styleBtn.lightpurple:hover {
  background-color: #b13479 !important;
}
.styleBtn.receipt {
  background-color: #8d2929 !important;
  color: #ffffff !important;
}

.styleBtn.receipt:hover {
  background-color: #b13434 !important;
}
.styleBtn.receipt-download {
  background-color: #b81c1c !important;
  color: #ffffff !important;
}

.styleBtn.receipt-download:hover {
  background-color: #e80f0f !important;
}
.styleBtn.upload {
  width: 150px;
  background-color: #31d2cc !important;
  color: #ffffff !important;
}

.styleBtn.upload:before {
  content: "";
  display: inline-flex;
  align-items: center;
  width: 19px;
  height: 13px;
  background: url(../images/upload.svg) no-repeat center center;
  background-size: 100% auto;
  margin-left: 11px;
}

.styleBtn.upload:hover {
  background-color: #29ccc7 !important;
}
.styleBtn.disabled {
  width: 150px;
  color: #868585 !important;
}
.styleBtn.create {
  width: 150px;
  background-color: #31d2cc !important;
  color: #ffffff !important;
}
.styleBtn.create:hover {
  background-color: #29ccc7 !important;
}

/*----------------- Main Layout -----------------*/
.wrapMainLayout {
  display: flex;
  min-height: calc(100vh - 69px);
}

.LayoutMenu {
  width: 255px;
  flex-grow: 0;
  align-items: stretch;
  -webkit-box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
}

.LayoutContent {
  width: calc(100% - 255px);
  flex-grow: 1;
  align-items: stretch;
  padding: 20px;
}

/*----------------- Page Name -----------------*/
.bPageName {
  background-color: #f6f7f9;
  border-left: 5px solid #024d85;
  font-size: 20px;
  font-weight: 400;
  padding: 10px 10px 10px 20px;
  color: #024d85;
  -webkit-box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  width: 100%;
}

/*----------------- Box Content -----------------*/
.bContentMain {
  width: 100%;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
}

.bNameContent {
  background-color: #f6f7f9;
  border-left: 5px solid #024d85;
  padding: 10px 20px;
  color: #024d85;
}

.bContent {
  padding: 10px 10px 10px 10px;
}

.text-to {
  display: flex;
  align-items: center;
  margin: 0 25px;
  color: #024d85;
  font-weight: 500;
}

/*----------------- Form Element -----------------*/
.MuiFormLabel-root,
.MuiInputBase-root {
  font-family: "Kanit", sans-serif !important;
}

.MuiFormControl-root {
  padding: 5px 0 0 0 !important;
}

.MuiInputLabel-formControl {
  top: 3px !important;
  left: 15px !important;
}

.MuiInputBase-input {
  padding: 0 15px 13px !important;
}

.MuiInput-underline:before {
  border-bottom: 1px solid #c3c4c6 !important;
}

.MuiInput-underline.Mui-disabled:before {
  border-bottom-style: dotted !important;
}

.MuiInputLabel-shrink {
  color: #3279b7 !important;
}

.MuiTypography-body1 {
  font-family: "Kanit", sans-serif !important;
}

.groupRadio {
  padding: 0 15px 13px !important;
}

.MuiFormControlLabel-root {
  margin-right: 30px !important;
}

.MuiFormGroup-row {
  margin-top: 5px;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: #024d85 !important;
}

.MuiIconButton-colorSecondary:hover {
  background-color: #024d850a !important;
}

.TagStatus {
  position: relative;
  display: block;
  width: fit-content;
  border: 1px solid #b3b3b3;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  line-height: 32px;
  padding: 0 10px 0 33px;
  text-transform: capitalize;
  background-color: #f5f5f5;
}

.TagStatus:before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
  background-color: #b3b3b3;
  position: absolute;
  top: 8px;
  left: 10px;
}

.TagStatus.complete {
  background-color: #e8ffeb;
  border-color: #14a725;
}

.TagStatus.complete:before {
  background-color: #14a725;
}

.TagStatus.pending {
  background-color: #fff4ea;
  border-color: #ea7c1e;
}

.TagStatus.pending:before {
  background-color: #ea7c1e;
}

.TagStatus.cancel {
  background-color: #fff4f4;
  border-color: #b73232;
}

.TagStatus.cancel:before {
  background-color: #b73232;
}

/*----------------- MainMenu -----------------*/
.toolbarMain {
  background-color: #f6f7f9 !important;
}

.toolbarMain:after {
  content: "";
  position: absolute;
  bottom: -5px;
  display: block;
  width: 100%;
  height: 5px;
  background-color: #024d85;
}

.boxLogoSmall {
  display: block;
  background: url(../images/logo-small.png) no-repeat center center;
  background-size: 100% auto;
  width: 180px;
  height: 44px;
}

.toolbarMain .MuiToolbar-gutters {
  padding: 0 20px;
}

.UserFunction {
  padding: 20px;
  border: 1px solid #eaeaea;
}

.tUsername {
  text-align: center;
  letter-spacing: 1px;
}

.tPosition {
  text-align: center;
  color: #024d85;
  font-size: 14px;
  letter-spacing: 1px;
  margin-bottom: 20px;
}

.MainMenuList {
  padding: 0px;
}
.MainMenuList .MuiAccordionSummary-root {
  min-height: inherit;
}
.MainMenuList .MuiPaper-elevation1 {
  box-shadow: none;
}
.MainMenuList .MuiAccordion-root:before {
  display: none;
}
.MainMenuList .MuiAccordion-root.Mui-expanded,
.MainMenuList .MuiAccordion-root.Mui-expanded:first-child {
  margin: 0;
}
.MainMenuList .MuiAccordionSummary-root.Mui-expanded {
  min-height: inherit;
}
.MainMenuList .MuiAccordionSummary-content.Mui-expanded {
  margin: 0;
  color: #3279b7;
}
.MainMenuList .MuiAccordionDetails-root {
  padding-top: 0;
  padding-bottom: 0;
}
.MainMenuList .MuiSvgIcon-root {
  fill: #3279b7;
}

.MenuList li {
  margin-bottom: 10px;
  list-style: none;
  cursor: pointer;
}

.MenuList li a {
  color: #555555;
}

.MenuList li a:hover,
.MenuList li.active a {
  color: #024d85;
}
.MenuList li.active a {
  font-weight: bold;
  font-size: 18px;
}
.MenuList li a:before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 10px;
  background-color: #555555;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.MenuList li a:hover:before,
.MenuList li.active a:before {
  background-color: #024d85;
}

/*----------------- Table Element -----------------*/
.tabelData .MuiInputBase-input {
  padding-top: 6px !important;
  padding-right: 24px !important;
  padding-bottom: 7px !important;
  padding-left: 8px !important;
}

.tabelData.MuiPaper-elevation1 {
  box-shadow: none;
  border: 1px solid #d5d5d5;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}

.tabelData .MuiTableHead-root .MuiTableCell-root {
  background-color: #f6f7f9 !important;
  font-size: 14px;
  font-weight: 400;
  color: #024d85;
  line-height: 22px;
}

.tabelData .MuiTableCell-root {
  font-family: "Kanit", sans-serif !important;
  /* border-bottom: none !important; */
  background: none !important;
  border-right: 1px solid #d5d5d5;
  color: #333333;
  padding: 10px;
}

.tabelData .MuiTableCell-root:last-child {
  /* border-right: none; */
}

.tabelData
  .MuiTableBody-root
  .MuiTableRow-root:nth-child(even)
  .MuiTableCell-root {
  background-color: #fafafa !important;
}

.tabelData .MuiTablePagination-root {
  background-color: #f6f7f9;
  border-top: 1px solid #d5d5d5;
}

.tabelData .MuiTableRow-root.MuiTableRow-hover:hover,
.tabelData
  .MuiTableRow-root:nth-child(even).MuiTableRow-hover:hover
  .MuiTableCell-root {
  background-color: rgb(238 246 255) !important;
}

/*----------------- Chip Element -----------------*/
.ChipPDF .MuiChip-deleteIcon {
  color: #b73232 !important;
  width: 15px !important;
  height: 15px !important;
}

.ChipPDF {
  font-family: "Kanit", sans-serif !important;
  font-size: 14px !important;
  margin: 0 5px;
}

.ChipPDF:before {
  content: "";
  display: inline-flex;
  width: 13px;
  height: 15px;
  background: url(../images/pdf.svg) no-repeat center center;
  background-size: auto 100%;
  margin-left: 13px;
}

.ChipPDF .MuiChip-deleteIcon {
  margin-right: 6px !important;
}

.textCondition {
  width: 100%;
  font-size: 12px;
  text-align: center;
  margin-top: 10px;
  color: #939495;
}

/* --------------------------------------------------- Mobile --------------------------------------------------- */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .toolbarMain .MuiToolbar-gutters {
    padding: 0;
  }

  .LayoutContent {
    padding: 10px;
  }
}
