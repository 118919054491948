/**
 * /*----------------- Default Style -----------------
 *
 * @format
 */

html {
  padding: 0;
  margin: 0;
  min-width: 100%;
  min-height: 100vh;
}

body {
  padding: 0;
  margin: 0;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

a:focus {
  text-decoration: none !important;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none !important;
}

a:active {
  text-decoration: none;
}

/*----------------- Fix Color Style -----------------*/
.fixColorTextRed {
  color: #b73232 !important;
}

/*----------------- Flex -----------------*/

.justifyContent-end {
  justify-content: flex-end;
}

.justifyContent-between {
  justify-content: space-between;
}

/*----------------- Show Hide Content -----------------*/

.Lpc {
  display: block !important;
}

.Lpc-tablet {
  display: block !important;
}

.Ltablet {
  display: none !important;
}

.Ltablet-mobile {
  display: none !important;
}

.Lmobile {
  display: none !important;
}

.Lpc-mobile {
  display: block !important;
}

.Lmobile-l {
  display: none !important;
}

.Lmobile-p {
  display: none !important;
}

/*----------------- Box -----------------*/
.line {
  width: 100%;
  height: 1px;
  background-color: #c3c4c6;
  margin-bottom: 10px;
  margin-top: 10px;
}

.mgBottom {
  margin-bottom: 20px;
}
.borderBT {
  padding-bottom: 10px;
  border-bottom: 1px solid #c3c4c6;
}

.box-S4 {
  width: 100%;
  display: flex;
}

.box-S3 {
  width: 74%;
  display: flex;
  flex-direction: column;
}

.box-S2 {
  width: 49%;
  display: flex;
  flex-direction: column;
}

.box-S1-3 {
  width: 32.333%;
  display: flex;
  flex-direction: column;
}

.box-S1 {
  width: 23.3%;
  display: flex;
  flex-direction: column;
}

.box-S05 {
  width: 11.65%;
  display: flex;
  flex-direction: column;
}
.box-S025 {
  width: 5.825%;
  display: flex;
  flex-direction: column;
}

.flex-evenly-center {
  align-items: center;
  justify-content: space-evenly;
  display: flex;
}

.flex-between-center {
  align-items: center;
  justify-content: space-between;
  display: flex;
}

.flex-center {
  align-items: center;
  justify-content: center;
  display: flex;
}

.flex-between-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flex-between-end {
  align-items: center;
  justify-content: flex-end;
  display: flex;
}

.dflex-inline {
  display: inline-flex;
}

.flex-between {
  justify-content: space-between;
  display: flex;
  flex-direction: unset;
}

.flex-item-warp {
  justify-content: space-between;
  flex-wrap: wrap;
  display: flex;
}

.noPadding {
  padding: 0;
}

.noMargin {
  margin: 0 !important;
}

.marginTop {
  margin-top: 10px;
}

.w100 {
  width: 100%;
}

.wAuto {
  width: auto;
}

/* ---------------------------------------------------**** Tablet Portrait ****--------------------------------------------------- */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  /*----------------- Show Hide Content -----------------*/

  .Lpc {
    display: none !important;
  }

  .Lpc-tablet {
    display: block !important;
  }

  .Ltablet {
    display: block !important;
  }

  .Ltablet-mobile {
    display: block !important;
  }

  .Lmobile {
    display: none !important;
  }

  .Lpc-mobile {
    display: none !important;
  }
}

/* ---------------------------------------------------**** Mobile ****--------------------------------------------------- */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  /*----------------- Show Hide Content -----------------*/

  .Lpc {
    display: none !important;
  }

  .Lpc-tablet {
    display: none !important;
  }

  .Ltablet {
    display: none !important;
  }

  .Ltablet-mobile {
    display: block !important;
  }

  .Lmobile {
    display: block !important;
  }

  .Lpc-mobile {
    display: block !important;
  }

  /*----------------- Box -----------------*/
  .box-S4,
  .box-S3,
  .box-S2,
  .box-S1-3,
  .box-S1 {
    width: 100%;
    display: block;
  }

  .B-formMain .b-row,
  .B-carddetail .b-tagBtn,
  #boxCalender .b-selectData,
  .warp-destiantionShow {
    display: block;
  }

  .mgBottom {
    margin-bottom: 10px;
  }

  /* .b-row .box-S4,
	.b-row .box-S3,
	.b-row .box-S2,
	.b-row .box-S1,
	.b-row .box-S1-3{
		margin: 10px 0;
	} */
}

.pcenter {
  height: 50%;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.jc-start {
  justify-content: start;
}

.txt-center {
  text-align: center;
}
